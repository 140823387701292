import { fetchGet, fetchPost,  fetchPut} from '../../router/https'
// 
function getGoodsList(data){
    return fetchGet("/mallapp/orderrefunds/page",data)
}
function getGoodsDetail(data){
    return fetchGet("/mallapp/orderrefunds/"+ data)
}
function getGoodsDetail2(data){
    return fetchGet("/mallapp/orderinfo/"+ data)
}
// 创建退款订单
function createRefundItem(data){
    return fetchPost("/mallapp/orderrefunds", data)
}
// 修改申请
function updateOrderrefunds(data){
    return fetchPut("/mallapp/orderrefunds", data)
}
// 撤销申请
function returnAllApply(data){
    return fetchPut("/mallapp/orderrefunds", data)
}
// 新增物流
function addLogistics (data){
    return fetchPost("/mallapp/orderinfo/addReturnOrderLogistcs", data)
}
// 查看物流
function getLogisticsData (data){
    return fetchPost("/mallapp/orderinfo/queryExpress", data)
}

export {
    getGoodsList ,
    getGoodsDetail,
    createRefundItem,
    getGoodsDetail2,
    returnAllApply,
    addLogistics,
    getLogisticsData,
    updateOrderrefunds
} 