<template>
  <div>
    <!-- 导航 -->
    <van-nav-bar title="退款详情" left-arrow @click-left="onClickLeft" />
    <div class="container">
      <div class="handle">
        <div class="title">
          <span style="font-size: 16px; margin-right: 10px">
            {{
              this.refundBottomInfo.status == 0
                ? "已撤销"
                : this.refundBottomInfo.status == 1
                ? "等待商家处理"
                : this.refundBottomInfo.status == 11
                ? "退款成功"
                : this.refundBottomInfo.status == 12
                ? "商家拒绝"
                : this.refundBottomInfo.status == 2
                ? "等待商家处理"
                : this.refundBottomInfo.status == 21
                ? "待退货"
                : this.refundBottomInfo.status == 22
                ? "商家拒绝"
                : this.refundBottomInfo.status == 211
                ? "退款成功"
                : this.refundBottomInfo.status == 212
                ? "商家拒绝"
                : ""
            }}
          </span>
          <van-count-down :time="time" v-if="this.refundBottomInfo.status == 1 || this.refundBottomInfo.status == 2 || 
            (this.refundBottomInfo.status == 21 && this.refundBottomInfo.isLogistice == 0)"/>
        </div>

        <div class="steps" >
          <van-steps direction="vertical" :active="stepNum" v-if="this.refundBottomInfo.status != 12
                      && this.refundBottomInfo.status != 22 && this.refundBottomInfo.status != 212 && this.refundBottomInfo.status != 0">
            <van-step>
              <h3>提交申请</h3>
            </van-step>
            <van-step>
              <h3>商家审核</h3>
            </van-step>
            <van-step
              v-if="
                this.refundBottomInfo.status == 2 ||
                this.refundBottomInfo.status == 21 ||
                this.refundBottomInfo.status == 211
              "
            >
              <h3>退回商品</h3>
            </van-step>
            <van-step>
              <h3>退款完成</h3>
            </van-step>
          </van-steps>
          <div class="desc">
            <span
              v-if="
                this.refundBottomInfo.status == 11 ||
                this.refundBottomInfo.status == 211
              "
            >
              <span>实退金额 ¥{{ this.refundBottomInfo.refundAmount }}</span
              ><br />
              <span
                >已退至你支付宝账号，可前往"支付宝-账单"查看{{
                  this.refundBottomInfo.updateTime
                }}</span
              >
            </span>
            <span
              v-if="
                this.refundBottomInfo.status == 1 ||
                this.refundBottomInfo.status == 2
              "
            >
              你已经成功发起退款审核，请耐心等待商家处理，商家同意或者超时未处理，系统将退款给你。如果商家拒绝,你可以修改申请再次发起，商家重新会处理
            </span>
            <span v-if="this.refundBottomInfo.status == 21 && this.refundBottomInfo.isLogistice == 0">
              商家同意退货，请将商品在规定时间内退回商家指导地址
            </span>
            <span v-if="this.refundBottomInfo.status == 21 && this.refundBottomInfo.isLogistice != 0">
              退货单号已填写，请等待物流退货至商家
            </span>
            <div v-if="this.refundBottomInfo.status == 12 || this.refundBottomInfo.status == 22 || 
                        this.refundBottomInfo.status == 212" style="padding-top:18px;">
              原因：{{this.refundBottomInfo.refuseRefundReson}}
            </div>
            <div v-if="this.refundBottomInfo.status == 12 || this.refundBottomInfo.status == 22 || 
                        this.refundBottomInfo.status == 212" style="padding-top:18px;">
              您可以同商家协商，修改申请后再次发起，商家重新会处理
            </div>
          </div>
        </div>
      </div>

      <!-- 商家审核同意后 -->
      <div class="returnAdress" v-if="refundBottomInfo.status == '21' && this.refundBottomInfo.isLogistice == 0">
        <p>退回地址</p>
        <div class="returnAdress-info">
          <img :src="refundBottomInfo.userHeadUrl" alt="" />
          <div class="returnAdress-info-middle">
            <div>
              <span style="margin-right: 5px">{{
                refundBottomInfo.shopName
              }}</span>
              <span>{{ refundBottomInfo.telNum }}</span>
            </div>
            <div>{{ refundBottomInfo.address }}</div>
          </div>
          <span
            class="returnAdress-info-span"
            @click="clipBoard(refundBottomInfo.telNum)"
            >复制</span
          >
        </div>
        <div class="returnAdress-button">
          <div></div>
          <van-button round size="mini" @click="toWriteLog"
            >填写物流信息</van-button
          >
        </div>
      </div>

      <!-- 物流信息 -->
      <div class="handle" style="margin-top: 15px;" v-if="refundBottomInfo.status == '21' && this.refundBottomInfo.isLogistice != 0">
        <div class="Ge-step" >
          <van-steps direction="vertical" active="-1" inactive-color="#3E85FF">
            <van-step>
              <div class="Ge-step-box">
                <div class="Ge-step-box-step">
                  <p style="color: #333;">物流状态</p>
                  <p class="Ge-step-box-step-p" style="color: #999;">{{refundBottomInfo.jsonobject.data[0].context}}</p>
                </div>
                <van-icon 
                  class="Ge-step-box-arrow" 
                  size="18" 
                  name="arrow"
                  @click="enterLogistics"
                  color="#999"/>
              </div>
            </van-step>
            <van-step>
              <div style="color: #333;">商家名  {{ refundBottomInfo.shopName }}</div>
              <div style="color: #999;">{{ refundBottomInfo.address }}</div>
            </van-step>
          </van-steps>
        </div>
      </div>

      <div class="item">
        <div class="_item">
          <div class="item-info">
            <img
              style="width: 80px; height: 80px"
              :src="this.refundBottomInfo.orderItem.picUrl"
              alt
            />
            <div class="desc-spec">
              <div class="desc">
                <span style="display: inline-block; width: 180px">{{
                  this.refundBottomInfo.orderItem.spuName
                }}</span>
                <span
                  >￥{{ this.refundBottomInfo.orderItem.paymentPrice }}</span
                >
              </div>
              <div class="spec">
                <span
                  >规格：{{ refundBottomInfo.orderItem.specInfo == 'null' || refundBottomInfo.orderItem.specInfo == null ? '统一规格' : refundBottomInfo.orderItem.specInfo}}</span
                >
                <span>*{{ this.refundBottomInfo.orderItem.quantity }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="return-money" style="padding-top: 15px">
          <span>退款金额</span>
          <span style="color: #fb2b53"
            >￥{{ this.refundBottomInfo.refundAmount }}</span
          >
        </div>
        <div class="return-money">
          <span>退款类型</span>
          <span>{{
            this.refundBottomInfo.orderType == 0 ? "普通订单 " : "酒店住宿订单"
          }}</span>
        </div>
        <div class="return-money">
          <span>退款原因</span>
          <span>{{ this.refundBottomInfo.refundReson }}</span>
        </div>
        <div class="return-money">
          <span>申请时间</span>
          <span>{{ this.refundBottomInfo.createTime }}</span>
        </div>
        <div class="return-money">
          <span style="flex: 1">订单编号</span>
          <span id="text2">{{ this.refundBottomInfo.orderItemId }}</span>
          <label style="display: block">
            <textarea id="textArea2" style="opacity: 0; width: 10px"></textarea>
          </label>
          <span class="copy2" @click="onCopy2">复制</span>
        </div>
        <div class="last-btn">
          <div></div>
          <div>
            <van-button @click="returnApply" round size="mini" v-if="this.refundBottomInfo.status != 0">撤销申请</van-button>
            <!-- 商家未审核前 -->
            <van-button
              v-if="refundBottomInfo.status == '2' || refundBottomInfo.status == '12' || refundBottomInfo.status == '22' || refundBottomInfo.status == '212'"
              color="#FB2B53"
              round size="mini"
              @click="updateApple"
            >修改申请</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsDetail, returnAllApply } from "@/api/afterSales"
import { Toast } from "vant";
import ClipBoard from "clipboard";
export default {
  data() {
    return {
      status: "",
      refundBottomInfo: {},
      stepNum: 0,
      time: 0
    };
  },
  created() {
    this.$store.commit(
      "setSession",
      this.$route.query.session || '$2a$10$QEfwByizvnS9Ma9qyoil0.lAeL.7xKPNKn4NzBS5oRIEN6k.LZQbi'
    );
    this.getGoodsDetail();
  },
  methods: {
    // 售后商品
    getGoodsDetail() {
      let id = this.$route.query.id || '1544504826106404865';
      getGoodsDetail(id).then((res) => {
        if (res.data.code === 0) {
          this.refundBottomInfo = res.data.data;
          let nowTime = parseInt(new Date().getTime());
          switch (this.refundBottomInfo.status) {
            case '1':{
              let endTime = Date.parse(this.refundBottomInfo.authSendTime.replace(/-/g, '/'));
              this.time = endTime - nowTime;
              this.stepNum = 1;
            }
              break;
            case '11':
              this.stepNum = 2;
              break;
            case '2':{
              let endTime = Date.parse(this.refundBottomInfo.authSendTime.replace(/-/g, '/'));
              this.time = endTime - nowTime;
              this.stepNum = 1;
            }
              break;
            case '21':{
              let endTime = Date.parse(this.refundBottomInfo.logisticsendTime.replace(/-/g, '/'));
              this.time = endTime - nowTime;
              this.stepNum = 2;
            }
              break;
            case '211':
              this.stepNum = 3;
              break;

            default:
              break;
          }
        }
      });
    },

    // 撤销申请
    returnApply() {
      let params = {
        id: this.$route.query.id,
        delFlag: "1",
        status: "0",
        operateType: '1'
      };
      returnAllApply(params).then((res) => {
        if (res.data.code === 0) {
          this.$Toast("撤销申请成功");
          this.getGoodsDetail();
        }
      });
    },

    onClickLeft() {
      // v-if="this.refundBottomInfo.status == 1"
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    // 复制
    clipBoard(orderNo) {
      const clipBoard = new ClipBoard(".returnAdress-info-span", {
        text: () => {
          return orderNo;
        },
      });
      clipBoard.on("success", () => {
        this.$toast("复制成功");
        clipBoard.destroy();
      });
      clipBoard.on("error", () => {
        this.$toast("复制失败");
      });
    },

    // 填写物流信息
    toWriteLog() {
      this.$router.push({
        path: "/writeLog",
        query: {
          id: this.refundBottomInfo.id,
          first: 0,
          session: this.$route.query.session,
        },
      })
    },

    // 复制
    onCopy() {
      let textArea = document.getElementById("textArea");
      let text = document.getElementById("text");
      textArea.innerText = text.innerText;
      textArea.select();
      document.execCommand("copy");
      Toast("复制成功");
    },

    // 复制2
    onCopy2() {
      let textArea = document.getElementById("textArea2");
      let text = document.getElementById("text2");
      textArea.innerText = text.innerText;
      textArea.select();
      document.execCommand("copy");
      Toast("复制成功");
    },

    updateApple () {
      this.$router.push({
        path: '/applySale',
        query: {
          id: this.refundBottomInfo.orderId,
          first: 0,
          update: 'update',
          session: this.$route.query.session,
          returnId: this.$route.query.id
        }
      })
    },

    // 进入物流详情
    enterLogistics () {
      const picUrl = [this.refundBottomInfo.orderItem.picUrl]
      const data = {
        logistics: this.refundBottomInfo.logistics,
        logisticsNo: this.refundBottomInfo.logisticsNo,
        picUrl,
        logisticsDesc: this.refundBottomInfo.logisticsDesc
      }
      this.$router.push({
        path: '/checkLogistics',
        query: {
          data,
          info: {
            userName: this.refundBottomInfo.shopName,
            telNum: this.refundBottomInfo.telNum,
            address: this.refundBottomInfo.address
          }
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.returnAdress {
  background: #fff;
  margin: 15px 0;
  padding: 16px 26px 10px;
  font-size: 14px;
  box-sizing: border-box;
  p {
    color: #333;
  }
  &-info {
    margin: 20px 0;
    display: flex;
    img {
      width: 30px;
      height: 30px;
      border-radius: 999px;
      margin-right: 10px;
    }
    &-middle {
      flex: 1;
    }
    &-span {
      color: #fb2b53;
      text-decoration: underline;
    }
    &-span:active {
      color: rgb(204, 37, 37);
    }
  }
  &-button {
    display: flex;
    justify-content: space-between;
    .van-button--mini {
      height: 28px;
      padding: 0px 13px;
    }
  }
}
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
.container {
  margin-top: 46px;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 10px 0;
  z-index: 2;
  .handle {
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px;
    .title {
      margin-left: 20px;
    }
    .steps {
      .desc {
        padding: 5px 20px;
        color: #333333;
        font-family: PingFang-SC-Regular;
      }
    }
  }
  .item {
    background-color: #fff;
    width: 100%;
    // height: 300px;
    box-sizing: border-box;
    padding: 20px 20px 0;
    margin: 10px 0;
    ._item {
      .title {
        font-size: 17px;
      }
      .item-info {
        margin-top: 10px;
        display: flex;
        .desc-spec {
          margin-left: 10px;
          flex: 1;
          .desc {
            display: flex;
            justify-content: space-between;
          }
          .spec {
            margin-top: 10px;
            color: #999999;
            font-family: PingFang-SC-Regular;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .return-money {
      display: flex;
      height: 45px;
      align-items: center;
      // background-color: pink;
      justify-content: space-between;
      color: #333333;
      .copy2 {
        color: #fb2b53;
        margin-left: 10px;
        display: inline-block;
        border-bottom: 1px solid #fb2b53;
        height: 18px;
      }
    }
    .last-btn {
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      .van-button--mini {
        height: 28;
        padding: 0 17px;
      }
    }
  }
}

/deep/ .van-step--vertical {
  padding: 20px 20px 20px 0;
}
/deep/ .van-step--vertical:not(:last-child)::after {
  border: none;
}
/deep/ .van-step__title {
  margin-top: -11px;
}
/deep/ .van-step__title--active {
  color: #3e85ff;
}
/deep/ .van-step__icon--active {
  color: #3e85ff;
}
/deep/ .van-step--finish .van-step__circle,
.van-step--finish .van-step__line {
  background-color: #3e85ff;
}
/deep/.van-step--finish .van-step__line {
  background-color: #3e85ff;
}
.van-count-down{
  color: #999999;
  display: inline-block;
}
.Ge-step {
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-arrow:active {
      background: #ddd;
    }
    &-step {
      width: 90%;
      &-p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>